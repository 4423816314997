import React, { lazy } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
const TutorTransactionListPage = lazy(() => import('./tutorTransactions/list'))
const DashboardPage = lazy(() => import('./dashboard'))
const UserList = lazy(() => import('./user/list'))
const UserView = lazy(() => import('./user/view'))
const QuestionView = lazy(() => import('./question/view'))
const QuestionCreate = lazy(() => import('./question/create'))
const Callback = lazy(() => import('./callback'))
const TutorApplicationList = lazy(() => import('./tutorApplication/list'))
const ClarificationList = lazy(() => import('./clarification/list'))
const TutorApplicationViewPage = lazy(() => import('./tutorApplication/view'))
const QuestionCommentList = lazy(() => import('./question-comments/list'))

const Pages: React.FC = () => {
  return (
    <Switch>
      <Route exact path='/' component={DashboardPage} />
      <Route exact path='/callback' component={Callback} />
      <Route exact path='/tutors' component={UserList} />
      <Route exact path='/tutors/:id' component={UserView} />
      <Route exact path='/tutor-applications' component={TutorApplicationList} />
      <Route exact path='/students' component={UserList} />
      <Route exact path='/students/:id' component={UserView} />
      <Route exact path='/reservice-questions' component={DashboardPage} />
      <Route exact path='/reservice-questions/create' component={QuestionCreate} />
      <Route exact path='/reservice-questions/create/:id' component={QuestionCreate} />
      <Route exact path='/questions/:id' component={QuestionView} />
      <Route exact path='/clarifications' component={ClarificationList} />
      <Route exact path='/question-comments' component={QuestionCommentList} />
      <Route exact path='/tutor-application/:id' component={TutorApplicationViewPage} />
      <Route exact path='/tutor-transactions' component={TutorTransactionListPage} />
      <Redirect to='/' />
    </Switch>
  )
}

export default Pages
