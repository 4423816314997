import { HOST, PORT, AUTH0_DOMAIN } from './../settings'
import { AccessTokenRequestOptions } from 'use-auth0-hooks/dist/context/auth0-context'
export const ORIGIN = `${HOST}${PORT ? `:${PORT}` : ''}`
export const CALLBACK_PATH = '/callback'
export const CALLBACK_URL = `${ORIGIN}${CALLBACK_PATH}`

export const handleRedirectCallback = () => {
  window.location.href = '/'
}

export const handleLoginError = (err: Error | undefined) => {
  if (err && err.message) {
    console.error('login failed', err.message)
    throw new Error(err.message) // let general error page handle this
  }
}

export const handleAccessTokenError = (err: Error | undefined, accessTokenRequest: AccessTokenRequestOptions) => {
  if (err && err.message) {
    console.error('Failed to retrieve access token', err, err.message, accessTokenRequest)
    throw new Error(err.message)
  }
}

export const getFullName = (user: { [x: string]: object }) => {
  return user[`https://${AUTH0_DOMAIN}:full_name`]
}
