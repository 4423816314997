import { useReducer, useCallback } from 'react'
import { createContainer } from 'react-tracked'

export const NOTIFICATION = 'notification'
export const QUESTION_LIST = 'questionList'
export const QUESTION_INFO = 'questionInfo'
export const QUESTION_CLARIFICATION = 'questionClarification'
export const QUESTION_COMMENT = 'questionComment'
export const QUESTION_TRANSACTION = 'questionTransaction'
export const USER_LIST = 'userList'
export const USER_INFO = 'userInfo'
export const USER_QUESTIONS = 'userQuestions'
export const USER_ANSWERS = 'userAnswers'
export const FILTER = 'filter'
export const SETTINGS = 'settings'
export const FILTER_USER_QUESTIONS = 'filterUserQuestions'
export const FILTER_USER_ANSWERS = 'filterUserAnswers'
export const USER_ACTION_LOGS = 'userActionLogs'
export const TUTOR_APPLICATION = 'tutorApplication'

// Query params default
export const DEFAULT_PER = 8
export const DEFAULT_PAGE_NUMBER = 1
export const DEFAULT_ORDER_BY = 'createdAt'
export const DEFAULT_ORDER = 'desc'
export const DEFAULT_USER_ORDER_BY = 'rating'
export const DEFAULT_USER_ORDER = 'desc'

export type KeyValue = {
  [key: string]: object | string | void | any
}
export type StateProps = {
  [key: string]: any
}
export type Action = {
  type: string
  payload: object
}
const reducer = (state: StateProps, action: Action): StateProps => {
  const { type, payload } = action
  return {
    ...state,
    [type]: {
      ...state[type],
      ...payload,
    },
  }
}

export const initialState: StateProps = {
  [SETTINGS]: {
    viewType: 'questions',
  },
  [QUESTION_LIST]: {
    page: 1,
    questions: null,
    meta: null,
    filter: 0,
    query: null,
    isLoading: false,
  },
  [QUESTION_INFO]: {
    question: null,
    isLoading: false,
  },
  [FILTER]: {
    orderBy: null,
    order: DEFAULT_ORDER,
    per: DEFAULT_PER,
    page: DEFAULT_PAGE_NUMBER,
    count: null,
    totalPages: null,
    search: '',
    status: 1,
    rating: null,
  },
  [FILTER_USER_ANSWERS]: {
    orderBy: DEFAULT_ORDER_BY,
    order: DEFAULT_ORDER,
    per: 8,
    page: DEFAULT_PAGE_NUMBER,
    count: null,
    totalPages: null,
    search: '',
    status: 1,
    rating: null,
  },
  [FILTER_USER_QUESTIONS]: {
    orderBy: DEFAULT_ORDER_BY,
    order: DEFAULT_ORDER,
    per: 8,
    page: DEFAULT_PAGE_NUMBER,
    count: null,
    totalPages: null,
    search: '',
    status: 1,
    rating: null,
  },
  [USER_LIST]: {
    users: null,
    type: null,
    meta: null,
    status: null,
    query: null,
    isLoading: false,
  },
  [USER_INFO]: {
    id: null,
  },
  [USER_ACTION_LOGS]: {
    requestAgain: false,
  },
  [USER_QUESTIONS]: {
    questions: null,
    isLoading: null,
  },
  [USER_ANSWERS]: {
    answers: null,
    isLoading: null,
  },
  [NOTIFICATION]: {
    message: null,
    level: null,
    timeout: 0,
    onCloseCallback: false,
    response: null,
    inPage: false,
    noClose: false,
  },
  [TUTOR_APPLICATION]: {
    applicationData: undefined,
    isLoading: false,
  },
  [QUESTION_CLARIFICATION]: {
    isLoading: false,
  },
  [QUESTION_TRANSACTION]: {
    isLoading: false,
  },
  [QUESTION_COMMENT]: {
    isLoading: false,
  },
}

const useValue = () => useReducer(reducer, initialState)
export const { Provider, useTracked, useUpdate, useTrackedState } = createContainer(useValue)

export const useDispatchByName = (name: string) => {
  const dispatch = useUpdate()
  const update = useCallback(
    (newVal: object) => {
      dispatch({
        type: name,
        payload: newVal,
      })
    },
    [dispatch, name],
  )
  return update
}
