import { useAuth } from 'use-auth0-hooks'
import { AUTH0_API_IDENTIFIER } from '../settings'

export const useUserAccessToken = () => {
  const { user, isLoading, accessToken, isAuthenticated, login, logout } = useAuth({
    audience: AUTH0_API_IDENTIFIER,
    scope: 'read:things',
  })
  return {
    login,
    user,
    isLoading,
    accessToken,
    isAuthenticated,
    logout,
  }
}

export const enumKeys = <O extends object, K extends keyof O = keyof O>(obj: O): K[] => {
  return Object.keys(obj).filter((key) => Number.isNaN(+key)) as K[]
}
