import React, { ReactElement, Suspense, useEffect, useState } from 'react'
import Loading from './Loading'
import { useUserAccessToken } from '../../lib/utils'
import AppBar from './AppBar'
import { makeStyles, Container, Box, Typography, Button } from '@material-ui/core'
import DialogPopup from './Dialog'

type FrameTypes = {
  children: ReactElement
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  main: {
    position: 'relative',
    paddingBottom: theme.spacing(5),
    flex: 1,
  },
  dialogRoot: {
    width: '100%',
    maxWidth: '600px',
    margin: 'auto',
    padding: theme.spacing(2),
  },
  dialogContentWrapper: {
    padding: theme.spacing(3, 4),
  },
  dialogContent: {
    fontSize: '16px',
    color: '#555555',
    opacity: 0.7,
    margin: theme.spacing(2, 1, 0),
  },
  buttonLogin: {
    margin: theme.spacing(3),
  },
  typography: {
    margin: theme.spacing(2.5, 0),
    [theme.breakpoints.down('xs')]: {
      ...theme.typography.subtitle1,
    },
  },
}))

const Frame = ({ children }: FrameTypes): ReactElement => {
  const classes = useStyles()
  const { user, isLoading, isAuthenticated, login } = useUserAccessToken()
  const isLoggedIn = !isLoading && user && isAuthenticated
  const [showLoginConfirmation, setShowLoginConfirmation] = useState(false)

  const loginUser = () => {
    login({
      appState: {
        returnTo: '/',
      },
    })
  }

  useEffect(() => {
    if (!user && !isLoading && !isAuthenticated) {
      setShowLoginConfirmation(true)
      return
    }
    setShowLoginConfirmation(false)
  }, [user, isLoading, isAuthenticated])

  return (
    <Suspense fallback={<Loading />}>
      {!isLoggedIn ? (
        <Loading />
      ) : (
        <div className={classes.root}>
          <AppBar />
          <Container>
            <Box display='flex' mt={'50px'} width='100%'>
              {children}
            </Box>
          </Container>
        </div>
      )}
      {showLoginConfirmation && (
        <DialogPopup
          isOpen={showLoginConfirmation}
          onClose={() => {
            return false
          }}
          className={classes.dialogRoot}
          dialogContent={
            <div className={classes.dialogContentWrapper}>
              <Typography variant='h6' align='center' className={classes.typography}>
                Welcome to Zookal Admin!!
              </Typography>
              <Typography variant='h6' align='center' className={classes.typography}>
                Sign in using your account to proceed
              </Typography>
            </div>
          }
          dialogActions={
            <>
              <Button className={classes.buttonLogin} onClick={loginUser} color='primary' variant='contained'>
                Sign in
              </Button>
            </>
          }
        />
      )}
    </Suspense>
  )
}

export default Frame
