import ReactDOM from 'react-dom'
// import './index.css'
import Providers from './Providers'
// import * as serviceWorker from './serviceWorker'
import React from 'react'
import Frame from './components/layout/Frame'
import Routes from './pages/routes'

// const Pages = lazy(() => import('./pages'))
ReactDOM.render(
  <Providers>
    <Frame>
      <Routes />
    </Frame>
  </Providers>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister()
