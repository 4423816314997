import React, { useState, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useAuth } from 'use-auth0-hooks'
import { ExpandMore, ExitToApp } from '@material-ui/icons'

import {
  AppBar as MuiAppBar,
  Toolbar,
  Container,
  ListItemIcon,
  ListItemText,
  Typography,
  IconButton,
  Avatar,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
} from '@material-ui/core'
import Logo from '../../static/zookal-logo-white.svg'
import clsx from 'clsx'
import { getFullName, ORIGIN } from '../../lib/auth'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  navHeading: {
    marginLeft: theme.spacing(2),
    fontSize: '30px',
    verticalAlign: 'middle',
  },
  menuButton: {
    borderRadius: 4,
    marginRight: 'auto',
  },
  fullName: {
    color: '#fff',
    margin: theme.spacing(0, 1),
    overflow: 'hidden',
    // maxWidth: theme.spacing(11),
    maxHeight: theme.spacing(8),
    '&:first-child': {
      maxWidth: theme.spacing(20),
    },
  },
  logo: {
    flexGrow: 1,
    '& img': {
      verticalAlign: 'middle',
    },
  },
  expandMore: {
    color: '#fff',
  },
}))

const AppBar = () => {
  const classes = useStyles({})
  const { user, logout } = useAuth()
  const anchorRef = useRef(null as any)
  const [open, setOpen] = useState(false)
  // const theme = useTheme()
  // const atMD = useMediaQuery(theme.breakpoints.down('sm'), {
  //   defaultMatches: true,
  // })
  const handleSignOut = () => {
    logout({
      returnTo: ORIGIN,
    })
  }
  const handleClose = (event: any) => {
    if (anchorRef.current && anchorRef.current!.contains(event.target)) {
      return
    }
    setOpen(false)
  }
  const handleListKeyDown = (event: any) => {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    }
  }
  const handleToggle = (event: any) => {
    setOpen((prevOpen) => !prevOpen)
  }
  return (
    <MuiAppBar color='primary' position='static' variant='outlined'>
      <Container disableGutters>
        <Toolbar>
          <div className={classes.logo}>
            <img src={Logo} alt='Zookal Q&A' />
            <Typography variant='h6' className={classes.navHeading} display='inline'>
              Q&A&nbsp; Admin
            </Typography>
          </div>
          <IconButton
            ref={anchorRef}
            onClick={handleToggle}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup='true'
            className={clsx('navbar-icon-avatar', classes.menuButton)}
            color='inherit'
          >
            <Avatar src={user.picture} alt={user.name} />
            <Typography variant='body1' noWrap className={classes.fullName}>
              {getFullName(user)}
            </Typography>
            <ExpandMore className={classes.expandMore} />
          </IconButton>
          <Popper open={open} anchorEl={anchorRef.current} transition disablePortal>
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList autoFocusItem={open} id='menu-list-grow' onKeyDown={handleListKeyDown}>
                      <MenuItem
                        onClick={(event) => {
                          handleClose(event)
                          handleSignOut()
                        }}
                      >
                        <ListItemIcon>
                          <ExitToApp />
                        </ListItemIcon>
                        <ListItemText className='profile-menu-signout' primary='Sign out' />
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Toolbar>
      </Container>
    </MuiAppBar>
  )
}

export default AppBar
