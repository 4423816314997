import React, { useCallback } from 'react'
import Backdrop from '@material-ui/core/Backdrop'
import { NOTIFICATION, useTrackedState, initialState, KeyValue } from '../../lib/store'
import MuiAlert from '@material-ui/lab/Alert'
import { useNotification } from '../../lib/hooks'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  backDrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    alignItems: 'start',
  },
  alert: {
    marginTop: 64,
    [theme.breakpoints.down('xs')]: {
      '& p': theme.typography.caption,
      textAlign: 'center',
      '&>div:first-child': {
        display: 'none',
      },
      '&>div': {
        padding: 0,
      },
    },
  },
  alertInPage: {
    width: '100%',
    position: 'absolute',
    top: 0,
  },
}))

export const GeneralError = ({ errorResponse }: KeyValue) => {
  if (errorResponse) {
    const { status, data } = errorResponse
    if (status >= 400 && status < 500 && data && data.error) {
      return <Typography>{data.error}</Typography>
    }
  }
  return (
    <>
      <Typography> Sorry! Something went wrong </Typography>
    </>
  )
}
let handle: NodeJS.Timeout | NodeJS.Timeout
const Notification = () => {
  const { message, level, timeout, onCloseCallback, inPage, noClose }: KeyValue = useTrackedState()[NOTIFICATION]
  const classes = useStyles({ inPage, noClose })
  const notify = useNotification()
  useCallback(() => {
    clearTimeout(handle)
    if (timeout && timeout > 0) {
      handle = setTimeout(() => {
        notify(initialState[NOTIFICATION])
      }, timeout)
    }
  }, [timeout])()

  const handleOnClose = () => {
    clearTimeout(handle)
    if (onCloseCallback) {
      onCloseCallback()
    }
    notify(initialState[NOTIFICATION])
  }

  const Alert = useCallback(() => {
    return (
      <MuiAlert
        className={clsx(classes.alert, inPage && classes.alertInPage, 'notification-alert')}
        variant='filled'
        severity={level || 'success'}
        onClose={noClose ? () => false : handleOnClose}
      >
        {message}
      </MuiAlert>
    )
  }, [inPage, level, noClose, handleOnClose, message])

  if (inPage && message) {
    return <Alert />
  }
  return (
    <Backdrop className={classes.backDrop} open={!!message}>
      <Alert />
    </Backdrop>
  )
}

export default Notification
