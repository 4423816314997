import axios from 'axios'
import { API_HH_HOST, API_HOST } from '../settings'
axios.defaults.timeout = 20000

const CancelToken = axios.CancelToken
export const source = CancelToken.source()

const axiosInstance = axios.create({
  baseURL: API_HOST,
  timeout: 30000,
})

const axiosInstanceForV2 = axios.create({
  baseURL: API_HH_HOST,
  timeout: 30000,
})

export default (accessToken?: string | null, isV2Endpoint = false) => {
  if (accessToken) {
    axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`
    axiosInstanceForV2.defaults.headers.common.Authorization = `Bearer ${accessToken}`
  }

  return isV2Endpoint ? axiosInstanceForV2 : axiosInstance
}
