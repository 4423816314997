export const ENVIRONMENT = process.env.ENVIRONMENT || ''
export const HOMEWORK_WEB_ADMIN_S3_BUCKET_NAME = process.env.HOMEWORK_WEB_ADMIN_S3_BUCKET_NAME || ''
export const HOMEWORK_WEB_ADMIN_HOSTED_ZONE_NAME = process.env.HOMEWORK_WEB_ADMIN_HOSTED_ZONE_NAME || ''

// App
export const HOST = process.env.REACT_APP_HOMEWORK_WEB_ADMIN_HOST || ''
export const PORT = process.env.REACT_APP_HOMEWORK_WEB_ADMIN_PORT || ''

// Auth0 config
export const AUTH0_DOMAIN = process.env.REACT_APP_HOMEWORK_WEB_ADMIN_AUTH0_DOMAIN || ''
export const AUTH0_CLIENT_ID = process.env.REACT_APP_HOMEWORK_WEB_ADMIN_AUTH0_CLIENT_ID || ''
export const AUTH0_API_IDENTIFIER = process.env.REACT_APP_HOMEWORK_WEB_ADMIN_AUTH0_API_IDENTIFIER || ''

// Admin API host
export const API_HOST = process.env.REACT_APP_HOMEWORK_WEB_ADMIN_API_HOST || ''
export const API_HH_HOST = process.env.REACT_APP_HOMEWORK_WEB_ADMIN_HH_API_HOST_V2 || ''
