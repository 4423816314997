import { useEffect, useCallback, useState } from 'react'
import { useTracked, useUpdate, NOTIFICATION, initialState, KeyValue } from './store'
import { useUserAccessToken } from './utils'
import axiosInstance from './axios'
import get from 'lodash.get'
import { useHistory } from 'react-router-dom'

export const useTrackedByName = (name: string) => {
  const [state, dispatch] = useTracked()
  const update = useCallback(
    (newVal) => {
      dispatch({
        type: name,
        payload: newVal,
      })
    },
    [dispatch, name],
  )
  return [state[name], update]
}

export const useDispatchByName = (name: string) => {
  const dispatch = useUpdate()
  const update = useCallback(
    (newVal) => {
      dispatch({
        type: name,
        payload: newVal,
      })
    },
    [dispatch, name],
  )
  return update
}

export const useResetByName = (name: string) => {
  const update = useDispatchByName(name)
  useEffect(() => {
    return () => {
      update(initialState[name])
    }
  }, [name])
}

export const useDebounce = (newVal: string, delay: number, callback: (newVal: string) => void) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      callback(newVal)
    }, delay)

    return () => {
      clearTimeout(timer)
    }
  }, [newVal])
}

export const useNotification = () => {
  return useDispatchByName(NOTIFICATION)
}

type UseRequestApiProps = {
  url: string
  type: string
  requestAgain?: boolean
  requestNow?: boolean
  onDataFetched?: (data: any) => void
  isV2Endpoint?: boolean
}

type DataMetaProps = {
  total?: number
  total_pages?: number
}
export type ResponseDataProps = {
  questions?: object
  answers?: object
  question?: object
  users?: object
  meta?: DataMetaProps
}
export const useRequestApi = ({
  url,
  type,
  requestAgain = false,
  requestNow = true,
  onDataFetched,
  isV2Endpoint = false,
}: UseRequestApiProps) => {
  const dispatchLoadingByType = useDispatchByName(type)
  const { accessToken } = useUserAccessToken()
  const [data, setData] = useState<ResponseDataProps | null>(null)
  const [requestError, setRequestError] = useState(null)
  const notify = useNotification()
  let mounted = true

  useEffect(() => {
    const getData = async () => {
      if (!accessToken || !mounted || !url || url.includes('null') || url.includes('undefined') || !requestNow) return
      dispatchLoadingByType({
        isLoading: true,
      })
      try {
        const { data } = await axiosInstance(accessToken, isV2Endpoint).get(url)
        if (!mounted) return
        setData(data)
        if (onDataFetched) {
          onDataFetched(data)
        }
        dispatchLoadingByType({
          isLoading: false,
        })
      } catch (err) {
        dispatchLoadingByType({
          isLoading: false,
        })
        const errMessage = get(err, 'response.data.error')
        setRequestError(errMessage)
        console.error('Error useRequestApi', url, errMessage)
        notify({
          level: 'error',
          err: err,
          message: errMessage || 'Something went wrong.',
          location: `useRequestApi ${url}`,
          onCloseCallback: () => {
            // window.location.href = '/'
          },
        })
      }
    }
    getData()
    return () => {
      mounted = false
    }
  }, [accessToken, url, type, requestAgain, requestNow])

  return {
    data,
    requestError,
  }
}

export const useGetPageFromSearchParams = () => {
  const history: KeyValue = useHistory()
  const search = get(history, 'location.search')
  const checkIfPageInParams = () => {
    if (!search || search === '') return 1
    const page = Number(search.split('=')[1])
    return page
  }
  const page = checkIfPageInParams()
  return {
    page,
    search,
  }
}
