import React, { FunctionComponent, ReactNode, memo, ReactElement } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { Auth0Provider } from 'use-auth0-hooks'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core'
import CssBaseline from '@material-ui/core/CssBaseline'
import Notification from './components/layout/Notification'
import Loading from './components/layout/Loading'
import { Provider as StoreProvider } from './lib/store'
import { CALLBACK_URL, handleRedirectCallback, handleLoginError, handleAccessTokenError } from './lib/auth'
import theme from './lib/theme'
import { AUTH0_DOMAIN, AUTH0_CLIENT_ID } from './settings'
import { QueryClient, QueryClientProvider } from 'react-query'
import { SnackbarProvider } from 'notistack'

interface ThemeProviderProps {
  children: ReactNode
}

const handleRedirecting = (): React.ReactElement => <Loading />

const Providers: FunctionComponent<ThemeProviderProps> = memo(
  ({ children }: ThemeProviderProps): ReactElement => {
    return (
      <MuiThemeProvider theme={theme}>
        <Auth0Provider
          domain={AUTH0_DOMAIN}
          clientId={AUTH0_CLIENT_ID}
          redirectUri={CALLBACK_URL}
          onRedirectCallback={handleRedirectCallback}
          onLoginError={handleLoginError}
          onAccessTokenError={handleAccessTokenError}
          onRedirecting={handleRedirecting}
        >
          <StoreProvider>
            <QueryClientProvider client={new QueryClient()}>
              <SnackbarProvider
                maxSnack={3}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <BrowserRouter>{children}</BrowserRouter>
                <Notification />
              </SnackbarProvider>
            </QueryClientProvider>
          </StoreProvider>
        </Auth0Provider>
        <CssBaseline />
      </MuiThemeProvider>
    )
  },
)

export default Providers
